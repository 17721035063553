

import('./AppBootstrap')
  .then(async ({ renderApp }) => {
    await renderApp();
  })
  .catch();

export {};

